.icon-1,
.icon-2,
.icon-3 {
  position: absolute;
  left: 2px;
  top: 50%;
  width: 22px;
  height: 3px;
  background-color: black;
  transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
}

.icon-1 {
  transform: translateY(-8px);
  animation-delay: 100ms;
}

.icon-3 {
  transform: translateY(8px);
  animation-delay: 250ms;
}

.hamburger-icon {
  position: absolute;
  left: 24px;
  top: calc(38px / 2);
  height: 26px;
  width: 26px;
  z-index: 1001;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  div {
    background: #1f1f1f;
  }
}

.icon-1.a {
  transform: rotate(40deg);
}
.icon-3.b {
  transform: rotate(-40deg);
}
.icon-2.c {
  opacity: 0;
}

.clear {
  clear: both;
}
